import React from "react"
import Header from "../components/header"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const AwardsPage = () => (
  <Layout>
    <SEO title={"Awards"} />
    <Header />
    <Container>
      <Row>
        <Col>
          <h1>Awards</h1>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default AwardsPage
